.resultCard{
    display: contents;
}
.resultCard__header {
    border: 1px solid #0083f3;
    padding: 48px 4px;
    margin-top: 2%;
    border-radius: 4px;
    cursor: pointer; /* Change cursor to a pointer on hover */
    transition: background-color 0.2s;
}

.resultCard_lotteryname {
    color: #0083f3;
    font-size: 28px;
}
  
.resultCard_drawtime {
  color: #964b2e;
  font-size: 28px;
}

.bttn, .bttn:hover {
    color: #fff;
    background-color: #0083f3;
    font-size: 20px;
    font-weight: 600;
    margin-right: 2%;
}

.bttn1, .bttn1:hover {
    color: #fff;
    background-color: #0083f3;
    font-size: 20px;
    font-weight: 600;
    margin-right: 2%;
}

.resultCard__header.active {
    background-color: #0083f3;
    padding: 48px 4px;
    margin-top: 2%;
    border-radius: 4px;
}

.resultCard_lotteryname.active {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
}

.resultCard_drawtime.active {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
}
  
.bttn.active, .bttn.active:hover {
    color: #0083f3;
    background-color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-right: 2%;
}

.bttn1.active, .bttn1.active:hover {
    color: #0083f3;
    background-color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-right: 2%;
}
@media screen and (max-width: 1024px) {

}
    
@media screen and (max-width: 991px) {
    .resultCard__header.active {
        background-color: #0083f3;
        padding: 30px 4px;
        margin-top: 2%;
        border-radius: 4px;
    }
    .resultCard_lotteryname.active {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
    }
    
    .resultCard_drawtime.active {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
    }
    
    .resultCard_lotteryname {
      color: #0083f3;
      font-size: 24px;
    }
    
    .resultCard_drawtime {
      color: #964b2e;
      font-size: 24px;
    }

    .bttn.active, .bttn.active:hover {
        color: #0083f3;
        background-color: #fff;
        font-size: 20px;
        font-weight: 600;
        margin-right: 0;
        width: 47%;
        margin-top: 4%;
        margin-left: 1%;
    }
    
    .bttn1.active, .bttn1.active:hover {
        color: #0083f3;
        background-color: #fff;
        font-size: 20px;
        font-weight: 600;
        margin-right: 0;
        width: 96%;
        margin-top: 2%;
        margin-left: 1%;
    }
    
    .bttn, .bttn:hover {
        color: #fff;
        background-color: #0083f3;
        font-size: 20px;
        font-weight: 600;
        margin-right: 0;
        width: 47%;
        margin-top: 4%;
        margin-left: 1%;
    }
    
    .bttn1, .bttn1:hover {
        color: #fff;
        background-color: #0083f3;
        font-size: 20px;
        font-weight: 600;
        margin-right: 0;
        width: 96%;
        margin-top: 2%;
        margin-left: 1%;
    }
}

@media screen and (max-width: 767px) {
    .resultCard__header {
        border: 1px solid #0083f3;
        padding: 20px 0px;
        margin: 24px 0;
    }
    .resultCard_lotteryname.active {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
    }
    
    .resultCard_drawtime.active {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
    }
    
    .resultCard_lotteryname {
      color: #0083f3;
      font-size: 22px;
    }
    
    .resultCard_drawtime {
      color: #964b2e;
      font-size: 22px;
    }

    .bttn.active, .bttn.active:hover {
        color: #0083f3;
        background-color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-right: 0;
        width: 47%;
        margin-top: 4%;
        margin-left: 1%;
    }
  
    .bttn1.active, .bttn1.active:hover {
        color: #0083f3;
        background-color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-right: 0;
        width: 96%;
        margin-top: 2%;
        margin-left: 1%;
    }
    
    .bttn, .bttn:hover {
        color: #fff;
        background-color: #0083f3;
        font-size: 18px;
        font-weight: 600;
        margin-right: 0;
        width: 47%;
        margin-top: 4%;
        margin-left: 1%;
    }
    
    .bttn1, .bttn1:hover {
        color: #fff;
        background-color: #0083f3;
        font-size: 18px;
        font-weight: 600;
        margin-right: 0;
        width: 96%;
        margin-top: 2%;
        margin-left: 1%;
    }
}
@media screen and (max-width: 575px) {
  
}

@media screen and (max-width: 375px) {
}
@media screen and (max-width: 360px) {
    .resultCard__header.active {
        background-color: #0083f3;
        padding: 15px 0px;
        margin-top: 2%;
        border-radius: 4px;
    }
    .resultCard__header.active {
        background-color: #0083f3;
        padding: 20px 4px;
        margin-top: 2%;
        border-radius: 4px;
        margin: 24px 0;
    }

    .resultCard_lotteryname.active {
        color: #fff;
        font-size: 19px;
        font-weight: 600;
    }
    
    .resultCard_drawtime.active {
        color: #fff;
        font-size: 19px;
        font-weight: 600;
    }
    
    .resultCard_lotteryname {
      color: #0083f3;
      font-size: 17px;
    }
    
    .resultCard_drawtime {
      color: #964b2e;
      font-size: 17px;
    }

    .bttn.active, .bttn.active:hover {
        color: #0083f3;
        background-color: #fff;
        font-size: 16px;
        font-weight: 600;
        margin-right: 0;
        width: 47%;
        margin-top: 4%;
        margin-left: 1%;
    }
    
    .bttn1.active, .bttn1.active:hover {
        color: #0083f3;
        background-color: #fff;
        font-size: 16px;
        font-weight: 600;
        margin-right: 0;
        width: 96%;
        margin-top: 2%;
        margin-left: 1%;
    }
    
    .bttn, .bttn:hover {
        color: #fff;
        background-color: #0083f3;
        font-size: 16px;
        font-weight: 600;
        margin-right: 0;
        width: 47%;
        margin-top: 4%;
        margin-left: 1%;
    }
    
    .bttn1, .bttn1:hover {
        color: #fff;
        background-color: #0083f3;
        font-size: 16px;
        font-weight: 600;
        margin-right: 0;
        width: 96%;
        margin-top: 2%;
        margin-left: 1%;
    }
}