body {
  font-family: sans-serif;
}
.head {
  color: #0b81d9;
  font-size: 30px;
}
.head1 {
  color: #964b2e;
  font-size: 26px;
}

.para {
  color: #333333;
}
.para1 {
  color: #333333;
  font-size: 16px;
  margin-bottom: 0;
}
.linkhead {
  color: #964b2e;
  text-decoration: underline;
  font-size: 26px;
}
@media screen and (max-width: 767px) {
  .head {
    font-size: 22px;
    text-align: center;
  }
  .head1 {
    font-size: 18px;
    text-align: center;
  }
  .linkhead {
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (max-width: 360px) {
  .head {
    font-size: 20px;
    text-align: center;
  }
  .head1 {
    font-size: 16px;
    text-align: center;
  }
  .linkhead {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .head1 {
    font-size: 22px;
    text-align: left;
  }
  .linkhead {
    font-size: 22px;
    text-align: left;
  }
}
@media print
{
  .noprint
  {
	display:none
  }
}
