/* ViewResult.css */

/* Container for the PDF viewer */
.Example__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}

/* Styles for the PDF document */
.Example__container__document {
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 80%;
}

.result-image  {
  max-width: 100%;
 
}


/* Styles for booklet view */
.booklet-page {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

/* Page navigation controls */
.page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Page number display */
.page-navigation p {
  font-size: 18px;
  margin: 0 20px;
}

/* Navigation buttons container */
.navigation-buttons {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

/* Previous button */
.prev-button,
.next-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  outline: none;
}

.prev-button:disabled,
.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Position the Previous button to the left */
.prev-button {
  margin-left: 10px;
}

/* Position the Next button to the right */
.next-button {
  margin-right: 10px;
}

.react-pdf__Page.prevPage {
  position: absolute !important;
  z-index: 1;
}


/* Style the close button container (above the PDF viewer) */
.view-result-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}


/* Style the close button */
.close-button {
  position: absolute;
  top: 0.5rem; /* Adjust top spacing as needed */
  right: 0.3rem; /* Adjust right spacing as needed */
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background-color: #c0392b;
}

@media screen and (max-width: 767px) {
  .Example__container__document {
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 95%;
  }
  .close-button {
    position: absolute;
    top: 0.5rem; /* Adjust top spacing as needed */
    right: 0.1rem; /* Adjust right spacing as needed */
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 3px 6px;
    cursor: pointer;
    font-size: 16px;
  }
}