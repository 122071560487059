.result_heading {
    color: #0b81d9;
    font-size: 30px;
}
.result_heading1 {
  color: #964b2e;
  font-size: 26px;
}
.para {
    color: #333333;
}
@media screen and (max-width: 991px) {
  .result_heading1 {
    font-size: 22px;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .result_heading {
    font-size: 22px;
    text-align: center;
  }
  .result_heading1 {
    font-size: 18px;
    text-align: center;
  }
}
@media screen and (max-width: 360px) {
  .result_heading {
    font-size: 20px;
    text-align: center;
  }
  .result_heading1 {
    font-size: 16px;
    text-align: center;
  }
}