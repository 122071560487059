* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  background: #fff;
}

body {
  margin: 0;
}

main {
  margin-top: 6rem;
}
.row{
  margin-left:0px !important;
  margin-right: 0px !important;
}