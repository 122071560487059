.livevideo {
  height: 500px;
}

@media screen and (max-width: 767px) {
  .livevideo {
    height: 300px;
  }
}

