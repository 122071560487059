
.stickyheader {
  position: sticky;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 99;
  width: 100%;
  border-bottom: solid 1px var(--primary-color);
  top: 0;
  background: white;
}
.logo_img {
    width: 75%;
}
@media screen and (max-width: 360px) {
  .logo_img {
    width: 100%;
  }
}
