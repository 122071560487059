.notificationCard{
    display: contents;
}

.notificationCard__header {
    border: 1px solid #0083f3;
    margin-top: 2%;
    border-radius: 4px;
    cursor: pointer; /* Change cursor to a pointer on hover */
    transition: background-color 0.2s;
}