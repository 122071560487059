.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }

.loadtext{
  font-size: 18px;
  color: #FFFFFF;
}

.loadimg{
  margin-top: 11%;
   width: 15%;
}