.actrulehead {
  color: #964b2e;
  text-decoration: underline;
  font-size: 22px;
}

@media screen and (max-width: 767px) {
  .actrulehead {
    color: #964b2e;
    text-decoration: none;
    font-size: 18px;
  }
}
