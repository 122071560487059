
  
footer {
    margin-top: 6%;
}

footer ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
}

footer ul li {
    border-right: 1px solid #8c8c8c;
    color: #747474;
    padding: 0px 14px;
}

footer ul li:nth-last-child(1) {
  border-right: none;
} 

footer a {
    color: #747474;
    font-size: 18px;
}

footer p {
    color: #747474;
    text-align: center;
}

.back2Top {
    width: 50px;
    line-height: 40px;
    overflow: hidden;
    z-index: 999;
    /* display: none; */
    cursor: pointer;
    position: fixed;
    bottom: 35px;
    right: 20px;
    background-color: #797979;
    color: #fff;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
}

.back2Top:hover {
    background-color: #DDF;
    color: #000;
}