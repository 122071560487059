.form_head {
    color: #0b81d9;
    text-transform: uppercase;
    font-size: 22px;
    text-decoration: underline;
}
.goa_logo {
    width: 30%;
}
.mizo_logo {
    width: 30%;
}
.form_datetime input{
    border: none;
    /* border-bottom: 1px solid #ced4da; */
    font-size: 16px;
    color: #a1a5a8;
}
.form-control:input {
    outline: none;
}
.form_datepicker{
    z-index: -1;
    width: 300px;
}
.form_icon{
    position: absolute;
    bottom: 10px;
    right: 50px;
}
.uplimg_div {
    margin-left: auto;
    text-align: center;
    border: 1px solid grey;
    border-radius: 8px;
    padding: 0;
    display: grid;
}
.uplimg_div img {
    height: 150px;
    padding: 1px;
    border-radius: 8px;
}
.upl_inpt {
    position: fixed;
    bottom: 0px;
    left: 0;
    opacity: 0;
    z-index: -1;
}
.uplimg_text {
    background-color: #dfddde;
    margin: 0;
    color: #939192;
    padding: 8px;
    cursor: pointer;
    display: block;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.form_inp {
    border: none;
    border-bottom: 1px solid #ced4da;
    font-size: 16px;
    color: #a1a5a8;
}
.form_inp:focus {
    box-shadow: none;
}
.form_inp::placeholder {
    color: #a1a5a8;
    opacity: 1; /* Firefox */
}

.form_inp:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a1a5a8;
}

.form_inp::-ms-input-placeholder { /* Microsoft Edge */
  color: #a1a5a8;
}
.form_inp_busin {
    border: none;
    border-bottom: 1px solid #ced4da;
    display: inline;
    width: 70%;
    font-size: 16px;
    color: #a1a5a8;
}
.form_inp_busin:focus {
    box-shadow: none;
}
.form_label {
    color: #a1a5a8;
    font-size: 18px;
}
.form_inp_busin1 {
    border: none;
    border-bottom: 1px solid #ced4da;
    display: inline;
    width: 65%;
    font-size: 16px;
    color: #a1a5a8;
}
.form_inp_busin1:focus {
    box-shadow: none;
}
.form_inp_busin1::placeholder {
    color: #a1a5a8;
    opacity: 1; /* Firefox */
}
.form_inp_busin::placeholder {
    color: #a1a5a8;
    opacity: 1; /* Firefox */
}

.form_inp_busin:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a1a5a8;
}

.form_inp_busin::-ms-input-placeholder { /* Microsoft Edge */
  color: #a1a5a8;
}
.form_inp_busin1:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a1a5a8;
}

.form_inp_busin1::-ms-input-placeholder { /* Microsoft Edge */
  color: #a1a5a8;
}
.sign_text {
    border-top: 1px solid;
    padding-top: 12px;
    color: #333333;
}
  
.btn_cont, .btn_cont:hover {
    width: 100%;
    padding: 12px 0px;
    background-color: #0083f3;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}

.error_p {
    color: #bf1650;
}

.error_p::before {
  display: inline;
  content: "⚠ ";
}
.label_div {
	display: flex;
	justify-content: space-between;
}
.uploadImgPreview{
    width: inherit;
}
.form_btn, .form_btn:hover {
    background-color: #0183e1;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 60px;
    font-weight: 600;
}
.divleft_form {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 15px;
}

.divright_form {
	flex: 0 0 50%;
	max-width: 50%;
	padding-left: 15px;
}
.div5left_form {
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
	padding-right: 15px;
}

.div5right_form {
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
	padding-left: 15px;
}
.receipt_para {
    color: #a1a5a8;
    font-size: 18px;
}
.form_submit_inp{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: none;
    border-bottom: 1px solid #ced4da;
    font-size: 16px;
    color: #a1a5a8;
    outline: none;
}
.row_form {
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.div1_form {
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
	padding-left:15px;
	padding-right:15px;
}

.div2_form {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.div3_form {
	flex: 0 0 25%;
	max-width: 25%;
	padding-left:15px;
	padding-right:15px;
}
.div4_form {
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}

.div5_form {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.div7_form {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.div8_form {
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}
.revn_div {
    /* margin: 0 auto; */
    text-align: center;
    border: 1px solid grey;
    border-radius: 8px;
    padding: 40px 14px;
    margin-top: 8%;
}
@media screen and (max-width: 991px) {
    .uplimg_div {
        margin: 0 auto;	
    }
    .form_inp_busin {    
        display: block;
        width: 100%;
        font-size: 18px;
    }
    .form_inp_busin1 {    
        display: block;
        width: 100%;
        font-size: 18px;
    }
    .label_div {
		display: flex;
		justify-content: normal;
	}
    .divleft_from {
		flex: 0 0 100%;
		max-width: 100%;
		padding-right: 0;
	}
	
	.divright_from {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
	}

    .div5left_form {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
	}
	
	.div5right_form {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
	}
    .div1_form {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left:15px;
		padding-right:15px;
	}

	.div2_form {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.div3_form {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
	
	.div4_form {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 15px;
	}
	
	.div5_form {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 15px;
	}

	.div7_form {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 15px;
	}
    .div8_form {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 15px;
	}
}
@media screen and (max-width: 767px) {
    .form_head {
        color: #0b81d9;
        text-transform: uppercase;
        font-size: 22px;
        text-decoration: underline;
    }
    .form_btn, .form_btn:hover {
        background-color: #0183e1;
        color: #fff;
        text-transform: uppercase;
        padding: 10px 60px;
        font-weight: 600;
        width: 100%;
        margin-top: 4%;
    }
    .goa_logo {
        width: 90%;
    }
    
    .mizo_logo {
        width: 90%;
    }
}
@media screen and (max-width: 360px) {
}
