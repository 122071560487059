
.ticketQryHead{
    text-align: center;
    padding: 12px;
    background-color: #0083f3;
    color: white;
}
.ticketQryHead_text{
    font-size: 24px;
    color: white;
}
.ticketQryNum{
    background-color: #e8e9eb;
    padding: 30px 20px;
}
.ticketQryNum_icon{
    float: right;
    font-size: 22px;
    margin-top: 5px;
    cursor: pointer;
}

.ticketQryNum_input,.ticketQryNum_input:focus {
    width:25%;
    text-align: center;
    font-size:20px;
    text-transform:uppercase;
    color: #964b2e;
    font-weight: 600;
    display:inline;
    margin: 3px;
}
.ticketQryNum_input_number,.ticketQryNum_input_number:focus {
    width: 50%;
    text-align: center;
    font-size: 20px;
    text-transform:uppercase;
    color: #964b2e;
    font-weight: 600;
    display:inline;
    margin: 3px;
}

.ticketQry_bttn_verify,.ticketQry_bttn_verify:hover{
    color: #fff;
    background-color: #0083f3;
    width:70%;
    font-size: 1rem;
    font-weight: 400;
    padding: .375rem .75rem;
}

.ticketQryNum_result_winner{
    padding: 15px 16px;
    background-color: #5fef5f;
    margin-top: 2%;
}

.ticketQryNum_result_text_winner{
    margin: 0;
    /* font-size: 22px; */
    color: #333333;
    text-align: center;
}

.ticketQryNum_error_result_winner{
    padding: 15px 16px;
    background-color: #e0d1d161;
    margin-top: 2%;
}

.ticketQryNum_result_error_text_winner{
    margin: 0;
    color: brown;
    /* font-size: 22px; */
    text-align: center;
}

@media screen and (max-width: 991px) {
    .ticketQryNum {
        background-color: #e8e9eb;
        padding: 20px 20px;
    }
    
    .ticketQry_bttn_verify, .ticketQry_bttn_verify:hover {
        color: #fff;
        background-color: #0083f3;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        margin-top: 4%;
    }
    
    .ticketQryNum_result_winner {
        padding: 15px 10px;
        margin-top: 4%;
    }
    
    .ticketQryNum_result_text_winner {
        margin: 0;
        /* font-size: 24px; */
        margin-top: 4%;
    }
}
@media screen and (max-width: 767px) {
    .ticketQryHead_text {
        font-size: 18px;
    }
    
    .ticketQryNum_icon {
        float: right;
        font-size: 18px;
        margin-top: 2px;
        cursor: pointer;
    }
    
    .ticketQryNum {
        background-color: #e8e9eb;
        padding: 20px 0px;
    }
    
    .ticketQryNum_input_number, .ticketQryNum_input_number:focus {
        width: 70%;
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        color: #964b2e;
        font-weight: 600;
        display: inline;
    }
        
    .ticketQryNum_result_text_winner {
        margin: 0;
        font-size: 20px;
        margin-top: 4%;
    }
}
@media screen and (max-width: 575px) {
  
}

@media screen and (max-width: 375px) {    
    .ticketQryNum_result_text_winner {
        margin: 0;
        font-size: 17px;
        margin-top: 4%;
    }
}
@media screen and (max-width: 360px) {
    .ticketQryHead_text {
        font-size: 15px;
        color: white;
    }
}