.inptcontdiv {
  position: relative;
}
.error_p {
  color: #bf1650;
}

.error_p::before {
  display: inline;
  content: "⚠ ";
}
.inptcontdiv i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 20px;
  color: grey;
}

.inptcont {
  background-color: #f8f8f8;
  border: 1px solid #e6e6e6;
  color: #ababab;
  padding: 26px 0;
  padding-left: 40px;
}

.inptconttextarea {
  padding-top: 14px;
  background-color: #f8f8f8;
  border: 1px solid #e6e6e6;
  color: #ababab;
  padding-left: 40px;
}

.inptcont::placeholder {
  color: #ababab;
  opacity: 1; /* Firefox */
}

.inptcont:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ababab;
}

.inptcont::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ababab;
}

.inpt_contdiv {
  position: relative;
}

.inpt_contdiv i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 20px;
  color: grey;
}

.btncont,
.btncont:hover {
  width: 100%;
  padding: 12px 0px;
  background-color: #0083f3;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.socicon {
  margin-top: 5%;
}

.fbicon {
  font-size: 8rem;
  color: #3b5998;
}

.instaicon {
  font-size: 8rem;
  color: transparent;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}

.twiticon {
  font-size: 8rem;
  color: #00acee;
}

.teleicon {
  font-size: 8rem;
  color: #0088cc;
}
